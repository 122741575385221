import gql from "graphql-tag";
import interviewFragment from "./InterviewFragment.gql";

export const INTERVIEW_SCHEDULE_SUBSCRIPTION = gql`
  subscription InterviewScheduleSubscription {
    interviewSchedule {
      ...${interviewFragment}
    }
  }
`;

export const UPDATE_INTERVIEW_SCHEDULE_SUBSCRIPTION = gql`
  subscription UpdateInterviewScheduleSubscription {
    updateInterviewSchedule {
      ...${interviewFragment}
    }
  }
`;

export const JOINED_INTERVIEW_SCHEDULE_SUBSCRIPTION = gql`
  subscription JoinedInterviewScheduleSubscription {
    joinedInterviewSchedule
  }
`;
